import * as React from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import map from 'lodash/map';
import { getGif } from '../utils/playback';
import SelectionModal from './SelectionModal';

const VideosDoc = gql`
  query getVideos($playlist_id: uuid!) {
    playlists_by_pk(id: $playlist_id) {
      playlist_videos {
        id
        video_id
      }
    }
    videos {
      id
      name
      upload {
        id
        playback {
          id
          tokens {
            gif
          }
        }
      }
    }
  }
`;

const DeleteAllPlayListVideosDoc = gql`
  mutation DeleteAllPlaylistVideos($playlist_id: uuid!) {
    delete_playlist_video(where: { playlist_id: { _eq: $playlist_id } }) {
      returning {
        id
      }
    }
  }
`;

const InsertNewPlaylistVideosDoc = gql`
  mutation InsertNewPlaylistVideos($objects: [playlist_video_insert_input!]!) {
    insert_playlist_video(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

const VideoSelect = (props: any) => {
  const { data, loading } = useQuery(VideosDoc, {
    variables: { playlist_id: props.id },
  });
  const [deleteAll] = useMutation(DeleteAllPlayListVideosDoc);
  const [insertAll] = useMutation(InsertNewPlaylistVideosDoc);

  const handleSubmit = async (selected: string[]) => {
    const objects = map(selected, (s) => {
      return {
        video_id: s,
        playlist_id: props.id,
      };
    });
    await deleteAll({ variables: { playlist_id: props.id } });
    await insertAll({ variables: { objects: objects } });
  };
  
  if (loading) {
    return <>Loading</>
  }

  const videos = map(data.videos, (video) => ({
    id: video.id,
    name: video.name,
    imageSource: getGif(video.upload.playback)!,
  }));

  const initialIds = map(
    data.playlists_by_pk.playlist_videos,
    ({ video_id }) => video_id
  );

  return (
    <SelectionModal
      data={videos}
      name='Videos'
      handleSubmit={handleSubmit}
      selected={initialIds}
    />
  );
};

export default VideoSelect;
