import * as React from "react";
import { useInput } from 'react-admin';
import LinearProgress from '@material-ui/core/LinearProgress'
import { useState } from 'react'
import axios from 'axios'
import * as UpChunk from '@mux/upchunk'
import { ToastsStore } from "react-toasts";

function confirmExit() {
  return 'Your video is still uploading, are you sure you want to exit?';
}

const FileInput = (props: any) => {
  const {
    input: { onChange: onFormChange },
  } = useInput(props);
  const [progress, setProgress] = useState<number | null>(null);
  const onChange = async (event: any) => {
    window.onbeforeunload = confirmExit;
    ToastsStore.info('Uploading video');

    const file = event.target.files[0];
    const resp = await axios.post('https://otter-api.herokuapp.com/upload');
    const upload = UpChunk.createUpload({
      endpoint: resp.data.uploadUrl,
      file: file,
      chunkSize: 5120, // Uploads the file in ~5mb chunks
    });

    // subscribe to events
    upload.on('error', err => {
      window.onbeforeunload = null;
      ToastsStore.error('Video failed to be uploaded');
      console.error('💥 🙀', err.detail);
    });

    upload.on('progress', progress => {
      setProgress(progress.detail);
    });

    upload.on('success', () => {
      window.onbeforeunload = null;
      ToastsStore.success('Video uploaded sucessfully');
      console.log("success!");
    });

    onFormChange(resp.data.uploadId);
  }

  return (
    <>
      <input type='file' onChange={onChange} accept="video/*" />
      { progress && <LinearProgress variant="determinate" value={progress || 0} />}
    </>
  )
}

export default FileInput
