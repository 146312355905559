import * as React from 'react';
import { useState } from 'react';
import {
  makeStyles,
  Button,
  Modal,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {
  map,
  reject,
  includes,
} from 'lodash';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    backgroundColor: theme.palette.background.paper,
    width: 600,
    maxHeight: '85vh',
    overflow: 'scroll',
    padding: theme.spacing(1),
  },
  image: {
    maxHeight: 200,
  }
}));

interface ISelectionModalProps {
  data: {
    id: string,
    name?: string,
    imageSource: string,
  }[],
  name: string,
  handleSubmit: (selected: string[]) => void,
  selected: string[],
}

function SelectionModal(props: ISelectionModalProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<string[]>(props.selected);
  const classes = useStyles();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      setSelected([...selected, event.target.name]);
    } else {
      const newSelected = reject(selected, (name) => {
        return name === event.target.name;
      });
      setSelected(newSelected);
    }
  };

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    props.handleSubmit(selected);
    setIsOpen(false);
  }

  return <>
    <Button onClick={() => setIsOpen(!isOpen)}>{'Add ' + props.name}</Button>
    <Modal
      open={isOpen}
      className={classes.modal}
      onEscapeKeyDown={() => setIsOpen(false)}
      onBackdropClick={() => setIsOpen(false)}
    >
      <Box className={classes.modalContainer}>
        <h2 id="server-modal-title">{'Select ' + props.name}</h2>
        {props.data && (
          <FormControl component="form" onSubmit={handleSubmit}>
            <FormGroup>
              {map(props.data, (item) => {
                return (
                  <FormControlLabel
                    key={item.id}
                    control={
                      <Checkbox
                        checked={includes(selected, item.id)}
                        onChange={handleChange}
                        name={item.id}
                      />
                    }
                    label={
                      <>
                        {item.name && <p>{item.name}</p>}
                        <img className={classes.image} src={item.imageSource} />
                      </>
                    }
                  />
                );
              })}
            </FormGroup>
            <Button type="submit">{'Add ' + props.name}</Button>
          </FormControl>
        )}
      </Box>
    </Modal>
  </>
}

export default SelectionModal;
