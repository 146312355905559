import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const createClient = () => {
  const headers = {
    'X-Hasura-Admin-Secret': 'Supersecret',
  };

  return new ApolloClient({
    link: new HttpLink({
      uri: 'https://prime-maggot-86.hasura.app/v1/graphql',
      headers,
    }),
    cache: new InMemoryCache(),
  });
};

const client = createClient();

export default client;
