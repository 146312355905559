import * as React from 'react';
import {
  gql,
  useQuery,
  useMutation,
} from '@apollo/client';
import SelectionModal from './SelectionModal';
import { map } from 'lodash';

const ProductsDoc = gql`
  query getProducts($video_id: uuid!) {
    videos_by_pk(id: $video_id) {
      video_products {
        product {
          id
        }
      }
    }
    products {
      id
      image_link
    }
  }
`;

const DeleteAllVideoProductsDoc = gql`
  mutation DeleteAllVideoProductsDoc($video_id: uuid!) {
    delete_video_product(where: { video_id: { _eq: $video_id } }) {
      returning {
        id
      }
    }
  }
`;

const InsertNewVideoProducts = gql`
  mutation InsertNewVideoProducts($objects: [video_product_insert_input!]!) {
    insert_video_product(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

function ProductSelect(props: any) {
  const { data, loading } = useQuery(
    ProductsDoc,
    {
      variables: {
        video_id: props.id
      }
    }
  );
  const [deleteAll] = useMutation(DeleteAllVideoProductsDoc);
  const [insertAll] = useMutation(InsertNewVideoProducts);

  async function handleSubmit(selected: string[]) {
    const objects = map(
      selected,
      (id) => ({
        product_id: id,
        video_id: props.id,
      })
    );
    await deleteAll({ variables: { video_id: props.id }});
    await insertAll({ variables: { objects }});
  }

  if (loading) {
    return <>Loading</>;
  }

  const products = map(
    data.products,
    (product) => ({
      id: product.id,
      imageSource: product.image_link,
    })
  );

  const selected = map(
    data.videos_by_pk.video_products,
    ({ product }) => product.id
  );

  return (
    <SelectionModal
      data={products}
      name='Products'
      handleSubmit={handleSubmit}
      selected={selected}
    />
  );
}

export default ProductSelect;
