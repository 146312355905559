import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getGif, getThumbnail } from '../utils/playback';
import { gql, useQuery } from '@apollo/client';

const VideosDoc = gql`
  query getVideos($id: uuid!) {
    videos_by_pk(id: $id) {
      upload {
        playback {
          id
          tokens {
            thumbnail
            gif
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  image: {
    width: theme.spacing(20),
  },
}));

const ThumbnailField = (props: any) => {
  const styles = useStyles();
  const [isActive, setIsActive] = useState(false);
  const { loading, data } = useQuery(VideosDoc, {
    variables: { id: props.record.id },
  });

  if (loading) {
    return <>Loading</>
  }

  return <>
    <img
      className={styles.image}
      style={{
        position: 'absolute',
        opacity: isActive ? 1 : 0,
      }}
      onMouseOver={() => setIsActive(true)}
      onMouseOut={() => setIsActive(false)}
      src={getGif(data.videos_by_pk.upload.playback)}
      alt="gif"
    />
    <img
      className={styles.image}
      src={getThumbnail(data.videos_by_pk.upload.playback)}
      alt="thumbnail"
    />
  </>
};

export default ThumbnailField;
