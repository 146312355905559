import * as React from 'react';
import VideoSelect from './VideoSelect';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DateField,
  TextField,
  EditButton,
  TextInput,
} from 'react-admin';

export const PlaylistList = (props: any) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="created_at" />
      <TextField source="updated_at" />
      <EditButton basePath="/playlists" />
    </Datagrid>
  </List>
);

const VideoTitle = ({ record }: any) => {
  return <span>Playlist {record ? `"${record.name}"` : ''}</span>;
};

export const PlaylistEdit = (props: any) => (
  <Edit title={<VideoTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <VideoSelect {...props} />
    </SimpleForm>
  </Edit>
);

export const PlaylistCreate = (props: any) => (
  <Create title="Create a playlist" {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
