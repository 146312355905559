import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import hasuraDataProvider from 'ra-data-hasura';
import { VideoList, VideoEdit, VideoCreate } from './components/videos';
import {
  PlaylistList,
  PlaylistEdit,
  PlaylistCreate,
} from './components/playlists';
import { ApolloProvider } from '@apollo/client';
import client from './client/hasura';
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore,
} from "react-toasts";
import {
  ProductList,
  ProductEdit,
  ProductCreate,
} from './components/products';

const hasuraUrl = 'https://prime-maggot-86.hasura.app';
const headers = {
  'content-type': 'application/json',
  'X-Hasura-Admin-Secret': 'Supersecret',
};

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Admin dataProvider={hasuraDataProvider(hasuraUrl, headers)}>
        <Resource
          name="videos"
          list={VideoList}
          edit={VideoEdit}
          create={VideoCreate}
        />
        <Resource
          name="playlists"
          list={PlaylistList}
          edit={PlaylistEdit}
          create={PlaylistCreate}
        />
        <Resource name="playlist_video" />
        <Resource
          name="products"
          list={ProductList}
          edit={ProductEdit}
          create={ProductCreate}
        />
      </Admin>
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_RIGHT} store={ToastsStore} />
    </ApolloProvider>
  );
};

export default App;
