import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  UrlField,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  Create,
} from "react-admin";

export function ProductList(props: any) {
  return <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <ImageField source="image_link" label="Image" />
      <UrlField source="page_link" label="Page" />
      <EditButton basePath="/products" />
    </Datagrid>
  </List>
}

export function ProductEdit(props: any) {
  return <Edit {...props}>
    <SimpleForm>
      <TextInput source="image_link" />
      <TextInput source="page_link" />
    </SimpleForm>
  </Edit>
}

export function ProductCreate(props: any) {
  return <Create title="Create a Product" {...props}>
    <SimpleForm>
      <TextInput source="image_link" />
      <TextInput source="page_link" />
    </SimpleForm>
  </Create>
}
