import * as React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DateField,
  TextField,
  EditButton,
  TextInput,
} from 'react-admin';
import FileInput from './FileInput';
import ProductSelect from './ProductSelect';
import ThumbnailField from './ThumbnailField';

export const VideoList = (props: any) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="created_at" />
      <TextField source="updated_at" />
      <ThumbnailField label="Thumbnail" />
      <EditButton basePath="/videos" />
    </Datagrid>
  </List>
);

const VideoTitle = ({ record }: any) => {
  return <span>Video {record ? `"${record.name}"` : ''}</span>;
};

export const VideoEdit = (props: any) => (
  <Edit title={<VideoTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <ThumbnailField />
      <FileInput source="upload_id" />
      <ProductSelect {...props} />
    </SimpleForm>
  </Edit>
);

export const VideoCreate = (props: any) => (
  <Create title="Create a Video" {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <FileInput source="upload_id" />
    </SimpleForm>
  </Create>
);
