export const getGif = (playback?: any) => {
  if (!playback) {
    return;
  }
  return `https://image.mux.com/${playback.id}/animated.gif?token=${playback.tokens?.gif}`;
};

export const getThumbnail = (playback?: any, ext = 'jpg') => {
  if (!playback) {
    return;
  }
  return `https://image.mux.com/${playback.id}/thumbnail.${ext}?token=${playback.tokens?.thumbnail}`;
};

export const getVideo = (playback?: any, ext = 'm3u8') => {
  if (!playback) {
    return;
  }
  return {
    src: `https://stream.mux.com/${playback?.id}.${ext}?token=${playback?.tokens?.video}`,
    type: 'application/x-mpegURL',
  };
};
